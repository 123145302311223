// Load jQuery and Bootstrap
import JQuery from "jquery";
window.$ = window.JQuery = JQuery;
import "popper.js"
import "bootstrap"

// Load / init stimulus controllers from ./stimulus/index.js
import "./stimulus"

// Load / init axios
import axios from "axios"
const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
document.addEventListener("turbo:render", () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
})
