import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = []

  connect() {
    // nope
  }

  link(event) {
    let that = this
    let url  = this.data.get("link-url")

    let button         = event.currentTarget
    let id             = button.dataset.id
    let record         = event.target.closest(".record")
    let loader         = record.querySelector(".loader")
    let successMessage = record.querySelector(".success-message")
    let errorMessage   = record.querySelector(".error-message")

    that.disableButtons()
    loader.style.display = "block"
    button.style.display = "none"

    axios.post(url, {
      record_id: id
    })
    .then(response => response.data)
    .then(() => {
      successMessage.style.display = "block"
    })
    .catch(error => {
      console.log(error)
      errorMessage.style.display = "block"
    })
    .then(() => {
      loader.style.display = "none"
      that.enableButtons()
    })
  }

  disableButtons() {
    let otherButtons = this.element.querySelectorAll("button");
    otherButtons.forEach(button => { button.disabled = true; })
  }

  enableButtons() {
    let otherButtons = this.element.querySelectorAll("button");
    otherButtons.forEach(button => { button.disabled = false; })
  }

}
